
import _ from 'lodash';

export default {
  name: 'QuoteView',
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    quoteSelected() {
      const q = this.$store.state.userSettings.quote;
      const hasQuote = !!q && _.isDate(q.expiresAt) && this.$store.state.now.isBefore(q.expiresAt);
      return hasQuote ? q : undefined;
    },
    quote() {
      let q = this.$store.state.quotes.quotesForDay[0];

      if (typeof this.id === 'string' && this.id) {
        q = this.$store.state.quotes.quotes.find(q => q.id === this.id);
      } else if (this.quoteSelected) {
        q = this.$store.state.quotes.quotes.find(q => q.id === this.quoteSelected.id);
      }

      return q;
    },
  },
};
